<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="flex flex-col">
        <div class="w-11/12 mx-auto">
          <form @submit.prevent="submit">
            <h2 class="text-2xl mb-4 font-bold">Dados Básicos</h2>

            <div class="flex flex-col">
              <label class="mb-2" for="name">Nome:</label>
              <input
                v-model="$v.name.$model"
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none"
                id="name"
                type="text"
                placeholder="Nome"
                autocomplete="off"
                readonly
              />
              <div v-if="$v.name.$error">
                <div class="error" v-if="!$v.name.required">
                  Campo obrigatório.
                </div>
                <div class="error" v-else-if="!$v.name.maxLength">
                  O nome deve ter no máximo
                  {{ $v.name.$params.maxLength.max }} caracteres.
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="email">Email:</label>
              <input
                v-model="$v.email.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="email"
                type="text"
                placeholder="E-mail"
                autocomplete="off"
              />
              <div v-if="$v.email.$error">
                <div class="error" v-if="!$v.email.required">
                  Campo obrigatório.
                </div>
                <div class="error" v-else-if="!$v.email.maxLength">
                  O e-mail de acesso deve ter no máximo
                  {{ $v.email.$params.maxLength.max }} caracteres.
                </div>
              </div>
            </div>

            <div class="flex flex-col">
              <label class="mb-2" for="password">Senha</label>
              <input
                v-model="$v.password.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="password"
                type="password"
                placeholder="Senha"
                autocomplete="new-password"
              />
              <div v-if="$v.password.$error">
                <div class="error" v-if="!$v.password.minLength">
                  A senha deve ter no mínimo
                  {{ $v.password.$params.minLength.min }} caracteres.
                </div>
              </div>
            </div>

            <div class="flex flex-col">
              <label class="mb-2" for="password_confirmation"
                >Confirmar Senha</label
              >
              <input
                v-model="$v.password_confirmation.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="password_confirmation"
                type="password"
                placeholder="Confirmar Senha"
                autocomplete="new-password"
              />
              <div v-if="$v.password_confirmation.$error">
                <div class="error" v-if="!$v.password_confirmation.minLength">
                  A senha deve ter no mínimo
                  {{ $v.password_confirmation.$params.minLength.min }}
                  caracteres.
                </div>
                <div
                  class="error"
                  v-else-if="!$v.password_confirmation.sameAsPassword"
                >
                  As senhas devem ser idênticas.
                </div>
              </div>
            </div>

            <div class="flex justify-between">
              <button
                class="bg-gray-400 text-white px-4 py-2 rounded rounded-md border border-gray-400 focus:outline-none mt-5"
                @click.prevent="goBack"
              >
                Voltar
              </button>

              <button
                type="submit"
                class="bg-green-1000 text-white px-4 py-2 rounded rounded-md border border-gray-400 focus:outline-none mt-5"
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import {
  maxLength,
  minLength,
  required,
  sameAs
} from 'vuelidate/lib/validators';
import axios from '@/utils/axios';
import { mapGetters } from 'vuex';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'ProfileIndex',

  title() {
    return `${process.env.VUE_APP_NAME} | Meu Acesso`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: false,
      name: '',
      email: '',
      password: '',
      password_confirmation: ''
    };
  },

  validations: {
    name: {
      required
    },
    email: {
      required,
      maxLength: maxLength(100)
    },
    password: {
      minLength: minLength(8)
    },
    password_confirmation: {
      minLength: minLength(8),
      sameAsPassword: sameAs('password')
    }
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },

  methods: {
    goBack() {
      if (
        this.user.role.name === 'participant' ||
        this.user.role.name === 'Instructor'
      ) {
        this.$router.push({
          name: 'my-complete-curriculum'
        });
      } else {
        this.$router.push({
          name: 'search'
        });
      }
    },

    async fetchProfile() {
      this.isLoading = true;
      const profile = await axios.get('/api/perfil/usuario');

      this.name = profile.data.data.name;
      this.email = profile.data.data.email;
      this.isLoading = false;
    },

    submit() {
      if (this.$v.$invalid) {
        this.$v.name.$touch();
        this.$v.email.$touch();
        this.$v.password.$touch();
        this.$v.password_confirmation.$touch();
      } else {
        let profileAttributes = {
          name: this.name,
          email: this.email
        };

        if (this.password !== '') {
          profileAttributes.password = this.password;
          profileAttributes.password_confirmation = this.password_confirmation;
        }

        axios
          .patch('/api/perfil/usuario', profileAttributes)
          .then(({ data }) => {
            this.$toast.success(data.data.message);
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      }
    }
  },

  created() {
    this.fetchProfile();
  }
};
</script>

<style scoped></style>
